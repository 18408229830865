import React from 'react'

function SewadarCheck({ sewadar, chosens, setChosens }) {
    const selectSewadar = () => {
        if(chosens.includes(sewadar.id)){
            // Set False
            let chsCopy = [];
            chosens.map((d)=>{
                chsCopy.push(d);
            })
            let index = chsCopy.indexOf(sewadar.id);
            chsCopy.splice(index, 1);
            setChosens(chsCopy);

        }
        else{
            // Set True
            setChosens(oldArray => [...oldArray, sewadar.id]);
        }
    }
    return (
        <div>
            <div onClick={selectSewadar} type="button" class="list-group-item list-group-item-action" style={{ display: "flex", justifyContent: "space-between" }}>
                <label>{sewadar?.name} - {sewadar?.phoneNum || ""}</label>
                <input type="checkbox" checked={chosens.includes(sewadar.id) ? true : false} />
            </div>
        </div>
    )
}

export default SewadarCheck