import React, { useEffect, useState } from 'react';
import "./sign.css";
import { signInWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import googleIcon from '../assets/google-icon.png';
import { Link } from 'react-router-dom';
import bg from "../assets/background.jpg";
import isEmpty from '../helpers/isEmpty';


function SignIn() {

  //Declaring State Variables
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Setting Up the Type Writer
  const typeWriterPhrase = "Sign In";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndex((prev) => prev + (1));
    }, 300);
    return () => clearTimeout(timeout);
  }, [index]);

  auth.useDeviceLanguage();

  // Sign In Functions

  const signIn = async (event) => {
    event.preventDefault();

    await signInWithEmailAndPassword(auth, email.toLowerCase(), password).catch(error => {
      alert("Email ID or Password is Wrong.");
    });
    

  };


  return (
    <div>
      <div className='formDiv'>
        <h1 className='heading' style={{ textDecoration: "underline" }}>
          Sign In
        </h1>
        <p style={{ textAlign: "center", fontSize: "25px", marginBottom: "30px", marginTop: "30px" }}>Jai Guruji! This is the Admin Portal for Sewadars, if you are not an admin kindly do not login, Any non administrator activity can lead to termination of the Sewadar</p>
        <p style={{ textAlign: "center", fontSize: "25px", marginBottom: "30px", marginTop: "30px", fontWeight: "900" }}>Restricted Access only for SST Team, GKAMM</p>
        <form onSubmit={!email || !password ? (event) => event.preventDefault() : signIn}>
          <div className="mb-3 input-field">
            <label for="email" className="form-label">Email address</label>
            <input type="email" className="form-control-plaintext inputForm" placeholder='Enter your email' value={email} onChange={event => setEmail(event.target.value)} />
          </div>
          <div className="mb-3 input-field">
            <label for="password" className="form-label">Password</label>
            <input type="password" className="form-control-plaintext inputForm" placeholder='Enter your password' value={password} onChange={event => setPassword(event.target.value)} />
          </div>
          <div className='btn-container'>
            {
              isEmpty(email) || isEmpty(password) || ((password.length > 6) === false) ? <button type="submit" className="btn btn-secondary sign">Sign In</button> :
                <button type="submit" className="btn btn-primary sign">Sign In</button>
            }
          </div>
        </form>
      </div>
    </div>
  )
}

export default SignIn