import React, { useState } from 'react'
import Entry from '../components/Entry';

function Entries({ entries }) {
    const [viewing, setViewing] = useState(false);
    const [view, setView] = useState({});
    console.log(entries)
    return (
        <div>
            {!viewing ? <div class="list-group" style={{ padding: "10px" }}>
                {entries.map((entry) => {
                    return (
                        <>
                            <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                                setView(entry);
                                setViewing(true);
                            }}>{entry.name}</button>
                        </>

                    )
                })}
            </div> : <Entry setViewing={setViewing} entry={view} />}

        </div>
    )
}

export default Entries