import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import badgetypes from '../constants/badgetypes'
import { db } from '../firebase';
import isEmpty from '../helpers/isEmpty';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import sewas from '../data/sewas';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
const XLSX = require("xlsx");


function Attendance({ sewadars }) {
    // const [badgeCode, setBadgeCode] = useState(badgetypes[0]);
    const [badgeNo, setBadgeNo] = useState();
    const [searchName, setSearchName] = useState("");
    const [searchNumber, setSearchNumber] = useState("");

    const [msg, setMsg] = useState("");
    const [msgName, setMsgName] = useState("");
    const [msgNumber, setMsgNumber] = useState("");

    const [tab, setTab] = useState(1);
    const [loading, setLoading] = useState(false);

    // Mark Attendance by Badge Number
    const [sewadarImageCode, setSewadarImageCode] = useState("");
    const [sewadarNameCode, setSewadarNameCode] = useState("");
    const [departmentCode, setDepartmentCode] = useState("");
    const [roleCode, setRoleCode] = useState("");
    const [numberCode, setNumberCode] = useState("");
    const [badgeCode, setBadgeCode] = useState("");

    // Mark Attendance by Name
    const [sewadarImageName, setSewadarImageName] = useState("");
    const [sewadarNameName, setSewadarNameName] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [roleName, setRoleName] = useState("");
    const [numberName, setNumberName] = useState("");
    const [badgeName, setBadgeName] = useState("");


    // Mark Attendance by Phone Number
    const [sewadarImageNumber, setSewadarImageNumber] = useState("");
    const [sewadarNameNumber, setSewadarNameNumber] = useState("");
    const [departmentNumber, setDepartmentNumber] = useState("");
    const [roleNumber, setRoleNumber] = useState("");
    const [numberNumber, setNumberNumber] = useState("");
    const [badgeNumber, setBadgeNumber] = useState("");


    // const setCode = (e) => {
    //     setBadgeCode(e.target.value)
    // };

    const columns = [
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'phoneNum', headerName: 'Phone Number', width: 200 },
        { field: 'department', headerName: 'Department', width: 200 },
        { field: 'role', headerName: 'Role', width: 200 },
        { field: 'badgeCode', headerName: 'Badge Code', width: 200 },
        { field: 'badgeNo', headerName: 'Badge No', width: 200 },
    ];

    const mark = () => {
        setLoading(true);
        let bno = sewadars.filter(obj => {
            return parseInt(obj.badgeNo) === parseInt(badgeNo);
        });
        if (bno.length != 0) {
            if (bno[0].attendance === true) {
                setMsg(`Attendance has already been marked for ${bno[0].name}`)
                setLoading(false);
            }
            else {
                try {
                    updateDoc(doc(db, "sewadars", bno[0].id), {
                        attendance: true,
                        attendanceTime: serverTimestamp()
                    }).then(async () => {
                        setMsg(`Attendance Marked for ${bno[0].name}`)
                        setLoading(false);
                    }).catch(() => {
                        setMsg(`No Record Found`)
                        setLoading(false);
                    })
                } catch (error) {
                    setMsg(`No Record Found for the following Sewadar.`)
                    setLoading(false);
                }
            }
        }
        else {
            setMsg(`No Record Found for the following Sewadar.`)
            setLoading(false);
        }
    };


    const markName = () => {
        setLoading(true);
        let bno = sewadars.filter(obj => {
            return obj.name.toLowerCase().includes(searchName.toLowerCase());
        });
        if (bno.length != 0) {
            if (bno[0].attendance === true) {
                setMsgName(`Attendance has already been marked for ${bno[0].name}`)
                setLoading(false);
            }
            else {
                try {
                    updateDoc(doc(db, "sewadars", bno[0].id), {
                        attendance: true,
                        attendanceTime: serverTimestamp()
                    }).then(async () => {
                        setMsgName(`Attendance Marked for ${bno[0].name}`)
                        setLoading(false);
                    }).catch(() => {
                        setMsgName(`No Record Found`)
                        setLoading(false);
                    })
                } catch (error) {
                    setMsgName(`No Record Found for the following Sewadar.`)
                    setLoading(false);
                }
            }
        }
        else {
            setMsgName(`No Record Found for the following Sewadar.`)
            setLoading(false);
        }
    };



    const markNumber = () => {
        setLoading(true);
        let bno = sewadars.filter(obj => {
            return parseInt(obj.phoneNum) === parseInt(searchNumber);
        });
        if (bno.length != 0) {
            if (bno[0].attendance === true) {
                setMsgNumber(`Attendance has already been marked for ${bno[0].name}`)
                setLoading(false);
            }
            else {
                try {
                    updateDoc(doc(db, "sewadars", bno[0].id), {
                        attendance: true,
                        attendanceTime: serverTimestamp()
                    }).then(async () => {
                        setMsgNumber(`Attendance Marked for ${bno[0].name}`)
                        setLoading(false);
                    }).catch(() => {
                        setMsgNumber(`No Record Found`)
                        setLoading(false);
                    })
                } catch (error) {
                    setMsgNumber(`No Record Found for the following Sewadar.`)
                    setLoading(false);
                }
            }
        }
        else {
            setMsgNumber(`No Record Found for the following Sewadar.`)
            setLoading(false);
        }
    };




    function formatDateToCustomString(datex) {
        var date = datex.toDate();
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr',
            'May', 'Jun', 'Jul', 'Aug',
            'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const day = date.getDate().toString().padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${hours}:${minutes}:${seconds} - ${day} ${month} - ${year}`;
    }



    const arrayReturn = (sewadarsArray) => {
        let mySewadars = [];
        sewadarsArray.map((sewadar) => {
            // console.log(sewadar?.attendance === true ? sewadar.attendanceTime.toDate().toString() : "")
            mySewadars.push({
                id: sewadar.id,
                name: sewadar.name,
                phoneNum: sewadar.phoneNum,
                department: sewadar.department,
                role: sewadar.role,
                badgeCode: sewadar.badgeCode,
                badgeNo: sewadar.badgeNo,
                attendance: sewadar?.attendance === true ? "Present" : "Absent",
                attendanceTime: sewadar.attendanceTime ? formatDateToCustomString(sewadar.attendanceTime) : ""
            });
        });
        return mySewadars;
    }


    const arraySummaryReturn = (sewadarsArray) => {
        let myDepartments = [];
        sewas.map((department) => {
            let departmentSewadars = sewadars.filter((e) => {
                return e.department?.trim() === department?.trim()
            });

            let presentSewadars = departmentSewadars.filter((e) => {
                return e.attendance === true
            });
            if (departmentSewadars.length != 0) {
                myDepartments.push({
                    name: department,
                    present: presentSewadars.length,
                    absent: departmentSewadars.length - presentSewadars.length
                });
            }
        });
        return myDepartments;
    }






    const downloadAttendance = () => {
        const Heading = [["ID(Don't Change this field)", "Sewadar Name", "Phone No", "Department", "Role", "Badge Code", "Badge No.", "Attendance", "Attendance-Time"]]
        const HeadingSummary = [["Department", "Present Sewadars", "Absent Sewadars"]]

        // Attendance Summary
        let summary = arraySummaryReturn();
        let presentSewadarsTotal = sewadars.filter((e) => {
            return e.attendance === true
        });
        summary.push({
            name: "Grand Total",
            present: presentSewadarsTotal.length,
            absent: sewadars.length - presentSewadarsTotal.length
        })
        const workbook = XLSX.utils.book_new();
        const worksheet3 = XLSX.utils.json_to_sheet(summary);
        XLSX.utils.sheet_add_aoa(worksheet3, HeadingSummary, { origin: 'A1' });

        XLSX.utils.book_append_sheet(workbook, worksheet3, "Summary");


        // Adding present sewadars
        let presentSewadars = sewadars.filter((e) => {
            return e.attendance === true
        });
        let newPresentSewadars = arrayReturn(presentSewadars);


        const worksheet1 = XLSX.utils.json_to_sheet(newPresentSewadars);
        XLSX.utils.sheet_add_aoa(worksheet1, Heading, { origin: 'A1' });

        XLSX.utils.book_append_sheet(workbook, worksheet1, "Present Sewadars");


        // Adding absent sewadars
        let absentSewadars = sewadars.filter((e) => {
            // console.log(e);
            return e.attendance !== true
        });
        let newAbsentSewadars = arrayReturn(absentSewadars);


        const worksheet2 = XLSX.utils.json_to_sheet(newAbsentSewadars);
        XLSX.utils.sheet_add_aoa(worksheet2, Heading, { origin: 'A1' });

        XLSX.utils.book_append_sheet(workbook, worksheet2, "Absent Sewadars");


        XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "SewadarAttendance.xlsx")

    };

    useEffect(() => {
        let bno = sewadars.filter(obj => {
            return parseInt(obj.badgeNo) === parseInt(badgeNo);
        });

        if (bno.length != 0) {
            setSewadarImageCode(bno[0].sewadarImage);
            setSewadarNameCode(bno[0].name);
            setDepartmentCode(bno[0].department);
            setRoleCode(bno[0].role);
            setNumberCode(bno[0].phoneNum);
            setBadgeCode(`${bno[0].badgeCode}-${bno[0].badgeNo}`);
        }
        else {
            setSewadarImageCode("");
            setSewadarNameCode("");
            setDepartmentCode("");
            setRoleCode("");
            setNumberCode("");
            setBadgeCode("");
        }
    }, [badgeNo])


    useEffect(() => {
        if (searchName) {
            let bname = sewadars.filter(obj => {
                return obj.name.toLowerCase().includes(searchName.toLowerCase());
            });

            if (bname.length != 0) {
                setSewadarImageName(bname[0].sewadarImage);
                setSewadarNameName(bname[0].name);
                setDepartmentName(bname[0].department);
                setRoleName(bname[0].role);
                setNumberName(bname[0].phoneNum);
                setBadgeName(`${bname[0].badgeCode}-${bname[0].badgeNo}`);
            }
            else {
                setSewadarImageName("");
                setSewadarNameName("");
                setDepartmentName("");
                setRoleName("");
                setNumberName("");
                setBadgeName("");
            }
        }
        else {
            setSewadarImageName("");
            setSewadarNameName("");
            setDepartmentName("");
            setRoleName("");
            setNumberName("");
            setBadgeName("");
        }
    }, [searchName]);



    useEffect(() => {
        // console.log("number: ", searchNumber)
        if (searchNumber) {
            let bnumber = sewadars.filter(obj => {
                return parseInt(obj.phoneNum) === parseInt(searchNumber);
            });

            if (bnumber.length != 0) {
                setSewadarImageNumber(bnumber[0].sewadarImage);
                setSewadarNameNumber(bnumber[0].name);
                setDepartmentNumber(bnumber[0].department);
                setRoleNumber(bnumber[0].role);
                setNumberNumber(bnumber[0].phoneNum);
                setBadgeNumber(`${bnumber[0].badgeCode}-${bnumber[0].badgeNo}`);
            }
            else {
                setSewadarImageNumber("");
                setSewadarNameNumber("");
                setDepartmentNumber("");
                setRoleNumber("");
                setNumberNumber("");
                setBadgeNumber("");
            }
        }
        else {
            setSewadarImageNumber("");
            setSewadarNameNumber("");
            setDepartmentNumber("");
            setRoleNumber("");
            setNumberNumber("");
            setBadgeNumber("");
        }
    }, [searchNumber]);


    return (
        <div>
            <ul class="nav nav-pills nav-fill p-4">
                <li class="nav-item">
                    <button onClick={() => { setTab(1) }} class={`nav-link ${tab === 1 ? "active" : ""}`}>Mark Attendance by Badge Number</button>
                </li>
                <li class="nav-item">
                    <button onClick={() => { setTab(4) }} class={`nav-link ${tab === 4 ? "active" : ""}`}>Mark Attendance by Name</button>
                </li>
                <li class="nav-item">
                    <button onClick={() => { setTab(5) }} class={`nav-link ${tab === 5 ? "active" : ""}`}>Mark Attendance by Phone Number</button>
                </li>
                <li class="nav-item">
                    <button onClick={() => { setTab(2) }} class={`nav-link ${tab === 2 ? "active" : ""}`}>Attendance Summary</button>
                </li>
                <li class="nav-item">
                    <button onClick={() => { setTab(3) }} class={`nav-link ${tab === 3 ? "active" : ""}`}>Attendance Data</button>
                </li>
            </ul>
            {tab === 1 ? <div>
                {!loading ? <div>
                    <h1 style={{ margin: 30, fontWeight: "bold", textAlign: "center" }}>Mark Sewadar Attendance</h1>
                    <hr />
                    <div style={{ margin: "30px" }}>

                        {/* <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewadar Badge Code*</label>
                        <select className="form-select choiceSel" aria-label="Badge Code" onChange={setCode}>
                            {badgetypes.map((code) => {
                                return (
                                    <option value={code} className='optionSel'>{code}</option>
                                )
                            })}
                        </select> */}
                        <h4 className='mt-3 mb-4 text-center' style={{ color: "red", fontWeight: "bold" }}>{msg}</h4>

                        <div>
                            {sewadarImageCode ? <img className='img-thumbnail' src={sewadarImageCode} style={{ maxHeight: "400px", display: "block", marginLeft: "auto", marginRight: "auto" }} /> : null}
                            {sewadarNameCode ? <h1 style={{ textAlign: "center" }}>{sewadarNameCode}</h1> : null}
                            {roleCode && departmentCode ? <h4 style={{ textAlign: "center" }}>{departmentCode} - {roleCode}</h4> : null}
                            {numberCode ? <h4 style={{ textAlign: "center" }}>Phone:- {numberCode}</h4> : null}
                            {badgeCode ? <h4 style={{ textAlign: "center" }}>Badge:- {badgeCode}</h4> : null}
                        </div>


                        <label lass="form-label mt-3" style={{ fontSize: 18, fontWeight: "bold", marginTop: 30 }}>Sewadar Badge Number*</label>
                        <input type='text' className='form-control' value={badgeNo} onChange={(e) => { setBadgeNo(e.target.value) }} placeholder="Enter Badge Number" />

                        {badgeNo ? <button onClick={mark} className='btn btn-lg btn-primary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button> : <button onClick={mark} className='btn btn-lg btn-secondary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button>}
                    </div>
                </div> : <div>
                    <img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("../assets/loading.gif")} />
                </div>}
            </div> : null}

            {tab === 4 ? <div>
                {!loading ? <div>
                    <h1 style={{ margin: 30, fontWeight: "bold", textAlign: "center" }}>Mark Sewadar Attendance</h1>
                    <hr />
                    <div style={{ margin: "30px" }}>

                        {/* <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewadar Badge Code*</label>
                        <select className="form-select choiceSel" aria-label="Badge Code" onChange={setCode}>
                            {badgetypes.map((code) => {
                                return (
                                    <option value={code} className='optionSel'>{code}</option>
                                )
                            })}
                        </select> */}
                        <h4 className='mt-3 mb-4 text-center' style={{ color: "red", fontWeight: "bold" }}>{msgName}</h4>

                        <div>
                            {sewadarImageName ? <img className='img-thumbnail' src={sewadarImageName} style={{ maxHeight: "400px", display: "block", marginLeft: "auto", marginRight: "auto" }} /> : null}
                            {sewadarNameName ? <h1 style={{ textAlign: "center" }}>{sewadarNameName}</h1> : null}
                            {roleName && departmentName ? <h4 style={{ textAlign: "center" }}>{departmentName} - {roleName}</h4> : null}
                            {numberName ? <h4 style={{ textAlign: "center" }}>Phone:- {numberName}</h4> : null}
                            {badgeName ? <h4 style={{ textAlign: "center" }}>Badge:- {badgeName}</h4> : null}
                        </div>


                        <label lass="form-label mt-3" style={{ fontSize: 18, fontWeight: "bold", marginTop: 30 }}>Sewadar Name*</label>
                        <input type='text' className='form-control' value={searchName} onChange={(e) => { setSearchName(e.target.value) }} placeholder="Enter Sewadar Name" />

                        {searchName ? <button onClick={markName} className='btn btn-lg btn-primary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button> : <button onClick={mark} className='btn btn-lg btn-secondary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button>}
                    </div>
                </div> : <div>
                    <img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("../assets/loading.gif")} />
                </div>}
            </div> : null}



            {tab === 5 ? <div>
                {!loading ? <div>
                    <h1 style={{ margin: 30, fontWeight: "bold", textAlign: "center" }}>Mark Sewadar Attendance</h1>
                    <hr />
                    <div style={{ margin: "30px" }}>

                        {/* <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewadar Badge Code*</label>
                        <select className="form-select choiceSel" aria-label="Badge Code" onChange={setCode}>
                            {badgetypes.map((code) => {
                                return (
                                    <option value={code} className='optionSel'>{code}</option>
                                )
                            })}
                        </select> */}
                        <h4 className='mt-3 mb-4 text-center' style={{ color: "red", fontWeight: "bold" }}>{msgNumber}</h4>

                        <div>
                            {sewadarImageNumber ? <img className='img-thumbnail' src={sewadarImageNumber} style={{ maxHeight: "400px", display: "block", marginLeft: "auto", marginRight: "auto" }} /> : null}
                            {sewadarNameNumber ? <h1 style={{ textAlign: "center" }}>{sewadarNameNumber}</h1> : null}
                            {roleNumber && departmentNumber ? <h4 style={{ textAlign: "center" }}>{departmentNumber} - {roleNumber}</h4> : null}
                            {numberNumber ? <h4 style={{ textAlign: "center" }}>Phone:- {numberNumber}</h4> : null}
                            {badgeNumber ? <h4 style={{ textAlign: "center" }}>Badge:- {badgeNumber}</h4> : null}
                        </div>


                        <label lass="form-label mt-3" style={{ fontSize: 18, fontWeight: "bold", marginTop: 30 }}>Sewadar Phone Number*</label>
                        <input type='text' className='form-control' value={searchNumber} onChange={(e) => { setSearchNumber(e.target.value) }} placeholder="Enter Sewadar Phone Number" />

                        {searchNumber ? <button onClick={markNumber} className='btn btn-lg btn-primary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button> : <button onClick={mark} className='btn btn-lg btn-secondary' style={{ display: "block", marginLeft: 'auto', marginRight: "auto", marginTop: "30px" }}>Mark Attendance</button>}
                    </div>
                </div> : <div>
                    <img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={require("../assets/loading.gif")} />
                </div>}
            </div> : null}

            {tab === 2 ? <div>
                <h1 style={{ margin: 30, fontWeight: "bold", textAlign: "center" }}>Attendance Summary</h1>
                <hr />
                <div style={{ margin: "30px" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>Department</TableCell>
                                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }} align="right">Sewadars Present</TableCell>
                                    <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }} align="right">Sewadars Absent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sewas.map((department) => {
                                    let departmentSewadars = sewadars.filter((e) => {
                                        return e?.department?.trim() === department?.trim()
                                    });

                                    let presentSewadars = departmentSewadars.filter((e) => {
                                        return e.attendance === true
                                    });
                                    if (departmentSewadars.length != 0) {
                                        return (
                                            <TableRow
                                                key={department}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {department}
                                                </TableCell>
                                                <TableCell align="right">{presentSewadars.length}</TableCell>
                                                <TableCell align="right">{departmentSewadars.length - presentSewadars.length}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                })}
                                {/* {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div> : null}

            {tab === 3 ? <div>
                <h1 style={{ margin: 30, fontWeight: "bold", textAlign: "center" }}>Attendance Marked for Sewadars:</h1>
                <hr />
                <button className="btn btn-primary" style={{ fontSize: "18px", display: "block", marginLeft: "auto", marginRight: "auto", alignItems: "center" }} onClick={downloadAttendance} ><FontAwesomeIcon style={{ marginRight: "10px" }} icon={faDownload} />Download Attendance</button>
                <div style={{ margin: "30px" }}>
                    <div style={{ height: "100vh", width: '100%' }}>
                        <DataGrid
                            rows={sewadars.filter((e) => {
                                return e.attendance === true
                            })}
                            columns={columns}
                            pageSize={15}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                        />
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

export default Attendance