import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useState } from "react";
import roles from "../data/roles";
import sewas from "../data/sewas";
import { db, storage } from "../firebase";

function Operations({ sewadars }) {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [photoing, setPhotoing] = useState("");
  const [setPhot, setSetPhot] = useState({});
  const [sewadarImage, setSewadarImage] = useState("");
  const [sewaDepartment, setSewaDepartment] = useState(sewas[0]);
  const [role, setRole] = useState(roles[0]);

  const searchx = () => {
    let rexsults = sewadars.filter((e) => {
      return e?.id?.toLowerCase() === search.toLowerCase() || (e?.name?.toLowerCase().includes(search.toLowerCase()))
    })
    console.log(rexsults);
    setResults(rexsults);
    setSearched(true);
  }
  const uploadChanges = async () => {
    const updates = async () => {
      if (sewadarImage) {
        const docRef = doc(db, 'sewadars', setPhot.id);
        const storageRef = await ref(storage, `sewadars/${setPhot.id}`);
        await uploadBytesResumable(storageRef, sewadarImage);
        await getDownloadURL(storageRef).then(async (url) => {
          await updateDoc(docRef, {
            sewadarImage: url,
            role: role,
            sewaDepartment: sewaDepartment
          })
        })
      }
      else {
        const docRef = doc(db, 'sewadars', setPhot.id);
        await updateDoc(docRef, {
          role: role,
          department: sewaDepartment
        })
      }
    }

    updates().then(() => {
      window.alert("Updates Completed");
      setPhotoing(false);
      setSearched(false);
    })
  };


  const setCorrectRole = (e) => {
    setRole(e.target.value);
  }

  const setCorrectSewa = (e) => {
    setSewaDepartment(e.target.value);
  }


  console.log("Role", role);

  return (
    <div>
      <div>
        <div style={{ padding: "10px" }}>
          {!searched ? <>
            <h2 style={{ marginLeft: "20px", textAlign: "center", fontWeight: "900" }}>Search Sewadar</h2>
            <label style={{ fontSize: "20px", marginTop: "30px", fontWeight: "bold" }}>Search for Sewadar by name or Portal ID</label>
            <input className="form-control" placeholder="Search for Sewadar by name or Portal ID" value={search} onChange={(e) => { setSearch(e.target.value) }} />
            <button className="btn btn-primary" style={{ fontSize: "25px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px" }} onClick={searchx} >Search Sewadars</button>
          </> : !photoing ? <>
            <button className="btn btn-primary" style={{ fontSize: "25px", borderRadius: "50%", marginLeft: "8px", marginBottom: "30px" }} onClick={() => { setSearched(false) }} ><FontAwesomeIcon icon={faArrowLeftLong} /></button>
            <div class="list-group">
              {results.map((sewadar) => {
                return (
                  <>
                    <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                      //   setView(sewadar);
                      //   setViewing(true);
                    }}>
                      <h3>{sewadar.name}</h3>
                      <h4>{sewadar.department} - {sewadar.role}</h4>
                      <h4>Phone Number - {sewadar.phoneNum ? sewadar.phoneNum : "NA"}</h4>
                      <button className="btn btn-success" onClick={() => { setPhotoing(true); setSetPhot(sewadar) }}>Change Photograph</button>
                    </button>
                  </>

                )
              })}
            </div>
          </> : <>
            <button className="btn btn-primary" style={{ fontSize: "25px", borderRadius: "50%", marginLeft: "8px", marginBottom: "30px" }} onClick={() => { setPhotoing(false) }} ><FontAwesomeIcon icon={faArrowLeftLong} /></button>
            <h3>{setPhot.name} - {setPhot.department} - {setPhot.role}</h3>
            {setPhot.sewadarImage ? <img className='img-thumbnail' src={setPhot.sewadarImage} style={{ maxHeight: "400px" }} /> : <h3>Photo Not Available</h3>}
            <div className="mb-3">
              <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewadar Image</label>
              <input type="file" className="form-control" aria-label="Choose file" required onChange={(e) => { setSewadarImage(e.target.files[0]) }} accept="image/*" />
              {sewadarImage ? <button className='btn btn-danger' style={{ margin: 8 }} onClick={() => setSewadarImage("")}>Clear File</button> : null}

            </div>
            {sewadarImage ? <img src={sewadarImage ? URL.createObjectURL(sewadarImage) : ""} alt="quizImage" className='img-thumbnail' style={{ maxWidth: "150px" }} /> : null}
            <br />
            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewa Department*</label>
              <select className="form-select choiceSel" aria-label="Sewa Department" value={sewaDepartment}  onChange={setCorrectSewa}>
                {sewas.map((sewa) => {
                  return (
                    <option value={sewa} className='optionSel'>{sewa}</option>
                  )
                })}
              </select>
            </div>
            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewa Role*</label>
              <select className="form-select choiceSel" aria-label="Sewa Department" value={role} onChange={setCorrectRole}>
                {roles.map((role) => {
                  return (
                    <option value={role} className='optionSel'>{role}</option>
                  )
                })}
              </select>
            </div>

            {role || sewaDepartment || sewadarImage ? <button className="btn btn-primary" style={{ fontSize: "25px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px" }} onClick={uploadChanges} >Upload Changes</button> : null}
          </>}



        </div>
      </div>
    </div>
  );
}

export default Operations;
