import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import React, { useState } from 'react'
import IDCard from '../components/IDCard';
import PermanentBadge from '../components/PermanentBadge';
const XLSX = require("xlsx");

function PermanentBadges({ sewadars, setPrinting, setNoNav, id }) {
    const [chose, setChose] = useState(false);
    const [startFrom, setStartFrom] = useState(0);
    const [endFrom, setEndFrom] = useState(0);
    const [filteredSewadars, setFilteredSewadars] = useState([]);
    const [starting, setStarting] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [chosenDataset, setChosenDataset] = useState([]);

    var doc = new jsPDF();



    const printing = () => {
        let distance = endFrom - startFrom;
        console.log("happening")
        console.log(distance)
        if (distance <= 120) {
            console.log("happening 2s")

            let SSewadars = [];
            for (let i = startFrom; i <= endFrom; i++) {
                SSewadars.push(chosenDataset[i - 1]);
            };
            console.log(SSewadars)

            setFilteredSewadars(SSewadars)
            setStarting(true)
            console.log(SSewadars);
            // setStarting(true);
        }
    };

    console.log(filteredSewadars)
    const uploadFile = async () => {
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { type: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
            console.log(data);
            let pickedAr = [];
            await data.map((dataSewadar) => {
                sewadars.map((sewadar) => {
                    if (sewadar.id === dataSewadar["Portal ID"]) {
                        pickedAr.push({
                            name: dataSewadar.Name,
                            phone: dataSewadar["Mobile No."],
                            department: dataSewadar.Department,
                            role: dataSewadar.Role,
                            day: dataSewadar.Day,
                            badgeNo: dataSewadar["Badge No."],
                            sewadarImage: sewadar.sewadarImage
                        });
                    }
                })
            });
            setChosenDataset(pickedAr);
            setChose(true);
            // setExcelData(data);
        }
        else {
            // setExcelData(null);
        }
    };
    const handleFile = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile) {
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFile(e.target.result);
                }
            }
            else {
                setExcelFile(null);
            }
        }
        else {
            alert('Please Select the Excel file');
        }
    };

    return (
        <div>
            <h4 className='text-center'>Permanent Badges under development, please do not use.</h4>
            <div style={{ margin: 30 }}>
                {chose === true ? starting === true ? <>
                    <button type="button" className='btn btn-primary' onClick={() => {
                        doc.save('Badges.pdf');

                    }}>Download Badges</button>
                    {filteredSewadars.map((sewadar) => {
                        console.log(sewadar);
                        return (
                            <>
                                {/* <IDCard id={id} sewadar={sewadar} doc={doc} /> */}
                                <PermanentBadge id={id} sewadar={sewadar} doc={doc} />
                            </>
                        )
                    })}
                </> :  <>
                    <div style={{ marginTop: "30px" }}>
                        <h2 style={{ fontWeight: "bold" }}>Start Printing Sewadar Permanent Badges</h2>
                        <h3>{(chosenDataset).length} Sewadars</h3>
                        <div style={{ paddingTop: "60px" }}>
                            <input type="number" class="form-control mb-3" placeholder="Enter from where do you want to start" value={startFrom === 0 ? "" : startFrom} onChange={(e) => { setStartFrom(e.target.value); console.log(startFrom) }} />
                            <input type="number" class="form-control" placeholder="Enter from where do you want to end" value={endFrom === 0 ? "" : endFrom} onChange={(e) => { setEndFrom(e.target.value); console.log(endFrom) }} />
                            {startFrom > 0 && endFrom > 0 && endFrom <= sewadars.length && startFrom <= sewadars.length ? <button type="button" class="btn btn-primary btn-lg mt-5" style={{ fontSize: "20px" }} onClick={printing}>Start Printing</button> : null}

                        </div>
                        <div style={{ padding: "25px" }}>
                            <h3>Hello You Need to Follow Some Steps to Print the ID Cards Properly</h3>
                            <h4 style={{ margin: "20px" }}>1. Make Sure You are running the Chrome browser.</h4>
                            <h4 style={{ margin: "20px" }}>2. The Maximum number of ID Cards you should print at a time are 50</h4>
                            <h4 style={{ margin: "20px" }}>3. Make Sure All the Information is correctly filtered & formed.</h4>
                            <h4 style={{ margin: "20px" }}>4. Once you are on the printing Screen Press CMD + P on A Mac or Control + P on a Windows to Start Printing</h4>
                        </div>
                    </div></> : <>
                    <>
                        <div style={{ marginTop: "30px" }}>
                            <h2 style={{ fontWeight: "bold" }}>Choose IDs to print</h2>
                            <h3>{(sewadars).length} Sewadars</h3>
                            <div style={{ paddingTop: "60px" }}>
                                <b style={{ marginBottom: "10px" }}>Kindly Don't upload Excel files more than thrice a day</b>
                                <input style={{ marginTop: "10px", marginBottom: "10px" }} type='file' className='form-control' onChange={handleFile} accept=".xlsx"></input>
                                <button type="button" className='btn btn-primary' data-bs-dismiss="modal" onClick={uploadFile} aria-label="Upload">Upload File</button>

                            </div>
                        </div>
                    </>
                </>}
            </div>
        </div>
    )
}

export default PermanentBadges
