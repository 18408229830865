import { faArrowLeftLong, faBackward, faBacon, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react';
import { addDoc, collection, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

function Entry({ entry, setViewing }) {
    const navigation = useNavigate();
    const perma = async () => {
        let id = entry.id
        const sewadar = entry;
        delete sewadar.id;
        const docRef = await addDoc(collection(db, "sewadars"), sewadar);
        await deleteDoc(doc(db, "entries", id));
        window.alert("Permanent Sewadar")
        navigation("/");
    }
    return (
        <div style={{ marginBottom: "20px" }}>
            <button className="btn btn-primary" style={{ fontSize: "25px", borderRadius: "50%", marginLeft: "8px" }} onClick={() => { setViewing(false) }} ><FontAwesomeIcon icon={faArrowLeftLong} /></button>
            <div style={{ textAlign: "center" }}>
                <img className='img-thumbnail' src={entry.sewadarImage} style={{ maxHeight: "400px" }} />
                <h1>{entry.name}</h1>
                <br />
                <button className="btn btn-primary" style={{ fontSize: "18px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px" }} onClick={perma} >Make Permanent Sewadar</button>
                <br />

                <label style={{ fontSize: "18px", marginTop: 16 }}>Phone Number - {entry.phoneNum}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Gender - {entry.gender}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Prefered Days:</label>
                <ul style={{ listStyle: "none" }}>
                    {entry.days.map((day) => {
                        return (
                            <li>{day}</li>
                        )
                    })}
                </ul>
                <label style={{ fontSize: "18px", marginTop: 16 }}>Date of Birth - {entry.dob}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Address - {entry.address}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Knows about Guruji Since - {entry.knAGuruji}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>In Sewa Since - {entry.inSewaSince}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Occupation - {entry.occupation}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Office Address - {entry.ofAddress}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Emergency Contact Name - {entry.emCon}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Emergency Contact Phone - {entry.emPho}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {entry.ref1Con}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {entry.ref1Pho}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {entry.ref2Con}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {entry.ref2Pho}</label>
                <br />
                <label style={{ fontSize: "18px", marginTop: 16 }}>Remarks/Comments - {entry.remarks}</label>




            </div>
        </div>
    )
}

export default Entry
