
import Navbar from '../components/Navbar';
import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import UI from '../components/UI';
import Sewadar from '../components/Sewadar';
import isEmpty from '../helpers/isEmpty';
const XLSX = require("xlsx");

function MainApp({ setNoNav, sewadars, id }) {
  const [viewing, setViewing] = useState(false);
  const [view, setView] = useState({});
  const [printing, setPrinting] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searches, setSearches] = useState([]);

  console.log(sewadars[402]);
  const printIDCards = () => {
    setPrinting(true);
  };
  const arrayReturn = () => {
    let mySewadars = [];
    sewadars.map((sewadar) => {
      mySewadars.push({
        id: sewadar.id,
        name: sewadar.name,
        phoneNum: sewadar.phoneNum,
        atPhoneNum: sewadar.atPhoneNum,
        department: sewadar.department,
        role: sewadar.role,
        badgeCode: sewadar.badgeCode,
        badgeNo: sewadar.badgeNo,
        perDepartment: sewadar.perDepartment,
        perRole: sewadar.perRole,
        perBadge: sewadar.perBadge,
        perDay: sewadar.perDay,
        attendance: sewadar.attendance ? "Present" : "Absent",
        carNumber: sewadar.carNumber,
        dob: sewadar.dob,
        age: sewadar.age,
        gender: sewadar.gender,
        prefDay: sewadar.prefDay,
        email: sewadar.email,
        address: sewadar.address,
        occupation: sewadar.occupation,
        ofAddress: sewadar.ofAddress,
        knAGuruji: sewadar.knAGuruji,
        inSewaSince: sewadar.inSewaSince,
        emCon: sewadar.emCon,
        emPho: sewadar.emPho,
        ref1Con: sewadar.ref1Con,
        ref1Pho: sewadar.ref1Pho,
        ref2Con: sewadar.ref2Con,
        ref2Pho: sewadar.ref2Pho,
        status: sewadar.fStatus ? sewadar.fStatus : "Inactive",
        remarks: sewadar.remarks,
        sewadarImage: sewadar.sewadarImage,
      });
    });
    return mySewadars;
  }
  const exportExcel = () => {
    // let workbook = new Excel.Workbook()
    // let worksheet = workbook.addWorksheet('your export sheet name')
    // worksheet.columns = [

    //   { header: 'Id(Do Not Change this field it could lead to errors)', key: 'id' },
    //   { header: 'Sewadar Name', key: 'name' },
    //   { header: 'Badge Code', key: 'badgeCode' },
    //   { header: 'Badge No.', key: 'badgeNo' },
    //   { header: 'Gender', key: 'gender' },
    //   { header: 'Sewa Department', key: 'department' },
    //   { header: 'Sewa Role', key: 'role' }

    // ]
    // worksheet.getRow(1).font = { bold: true }
    // sewadars.forEach((doc, index) => {
    //   const rowIndex = index + 2
    //   worksheet.addRow({
    //     ...doc,
    //   })

    // });
    // workbook.xlsx.writeBuffer('GurujiShiv.xlsx')
    // console.log(workbook.xlsx);
    // worksheet.state = 'visible';
    const Heading = [["ID(Don't Change this field)", "Sewadar Name", "Phone No", "Alternate Phone No", "Department", "Role", "Badge Code", "Badge Number", "Permanent Department", "Permanent Role" , "Permanent Badge Number" , "Permanent Day" ,`Attendance for ${id.eventName}`, "Car Number" ,"DOB", "Age", "Gender", "Preference Day", "Email", "Resedential Address", "Occupation", "Office Address", "Knows About Guruji Since", "In Sewa Since", "Emergency Contact Name", "Emergency Contact Phone Number", "Reference 1 Contact Name", "Reference 1 Contact Phone Number", "Reference 2 Contact Name", "Reference 2 Contact Phone Number", "Status" ,"Remarks", "Sewadar Image"]]
    let newSewadars = arrayReturn();
    console.log(newSewadars);
    const worksheet = XLSX.utils.json_to_sheet(newSewadars);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sewadars");
    XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "SewadarsExported.xlsx")

  };
  const unCheck = (a) => {
    if(a !== undefined){
      return a
    }
    else {
      return ""
    }
  }
  const importArrayReturn = (xSewadars) => {
    let mySewadars = [];
    xSewadars.map((sewadar) => {
      mySewadars.push({
        id: unCheck(sewadar["ID(Don't Change this field)"]),
        name: unCheck(sewadar["Sewadar Name"]),
        phoneNum: unCheck(sewadar["Phone No"]),
        atPhoneNum: unCheck(sewadar["Alternate Phone No"]),
        department: unCheck(sewadar["Department"]),
        role: unCheck(sewadar["Role"]),
        badgeCode: unCheck(sewadar["Badge Code"]),
        badgeNo: unCheck(sewadar["Badge Number"]),
        perDepartment: unCheck(sewadar["Permanent Department"]),
        perRole: unCheck(sewadar["Permanent Role"]),
        perBadge: unCheck(sewadar["Permanent Badge Number"]),
        perDay: unCheck(sewadar["Permanent Day"]),
        dob: unCheck(sewadar["DOB"]),
        age: unCheck(sewadar["Age"]),
        gender: unCheck(sewadar["Gender"]),
        prefDay: unCheck(sewadar["Preference Day"]),
        email: unCheck(sewadar["Email"]),
        address: unCheck(sewadar["Resedential Address"]),
        occupation: unCheck(sewadar["Occupation"]),
        ofAddress: unCheck(sewadar["Office Address"]),
        knAGuruji: unCheck(sewadar["Knows About Guruji Since"]),
        inSewaSince: unCheck(sewadar["In Sewa Since"]),
        emCon: unCheck(sewadar["Emergency Contact Name"]),
        emPho: unCheck(sewadar["Emergency Contact Phone Number"]),
        ref1Con: unCheck(sewadar["Reference 1 Contact Name"]),
        ref1Pho: unCheck(sewadar["Reference 1 Contact Phone Number"]),
        ref2Con: unCheck(sewadar["Reference 2 Contact Name"]),
        ref2Pho: unCheck(sewadar["Reference 2 Contact Phone Number"]),
        remarks: unCheck(sewadar["Remarks"]),
        sewadarImage: unCheck(sewadar["Sewadar Image"]),
        fStatus: unCheck(sewadar["Status"]),
        createdAt: serverTimestamp()
      });
    });
    console.log(mySewadars[0]);
    return mySewadars;
  }



  const uploadFile = () => {
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      // console.log(data);
      let excelReData = importArrayReturn(data);
      excelReData.map((sewadar)=>{
        const ref = doc(db, "sewadars", sewadar.id);
        let baSewadar = sewadar;
        delete baSewadar["id"];
        console.log(baSewadar);
        setDoc(ref, baSewadar);
      })
      // setExcelData(data);
    }
    else {
      // setExcelData(null);
    }
  };
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        }
      }
      else {
        setExcelFile(null);
      }
    }
    else {
      alert('Please Select the Excel file');
    }
  };

  const search = () => {
    var aSearch = searchTerm.toLowerCase();
    const searchResults = sewadars.filter(function (e) {
      return e?.name?.toLowerCase().includes(aSearch)
    });

    setSearches(searchResults);
  };

  useEffect(() => {
    if (isEmpty(searchTerm) === true) {
      setSearches([]);
    }
    else {
      search()
    }
  }, [searchTerm]);
  

  return (
    <div>
      {printing === false ? <div>
        {viewing === false ? <div class="list-group" style={{ padding: "10px" }}>

          <button className="btn btn-primary" style={{ fontSize: "25px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px" }} onClick={printIDCards} >Print ID Cards</button>
          <button className="btn btn-dark" style={{ fontSize: "25px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px" }} onClick={exportExcel} >Export Excel</button>
          <button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#uploadModal" style={{ fontSize: "25px", marginTop: 16, marginLeft: "40px", marginRight: "40px", marginTop: "10px", marginBottom: "40px" }}>Import Excel</button>

          <input type='text' className='form-control' value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder="Search..." style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }} />
          {searchTerm ? <button className="btn btn-primary" style={{ fontSize: "18px", marginTop: 16, marginBottom: 12}} onClick={()=>{setSearchTerm(""); setSearches([]);}}>Clear Results</button> : null}

          <div class="modal fade" id="uploadModal" tabindex="-1" aria-labelledby="uploadModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="uploadModalLabel">Upload Excel File - Format(.xlsx)</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style={{ padding: "30px", textAlign: "center" }}>
                  <b style={{ marginBottom: "10px" }}>Kindly Don't upload Excel files more than thrice a day</b>
                  <input style={{ marginTop: "10px", marginBottom: "10px" }} type='file' className='form-control' onChange={handleFile} accept=".xlsx"></input>
                  <button type="button" className='btn btn-primary' data-bs-dismiss="modal" onClick={uploadFile} aria-label="Upload">Upload File</button>
                </div>

              </div>
            </div>
          </div>







          <h2 style={{ marginLeft: "20px" }}>Sewadars</h2>
          {searches.length === 0 ? sewadars.map((sewadar) => {
            return (
              <>
                <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                  setView(sewadar);
                  setViewing(true);
                }}>{sewadar.name}</button>
              </>

            )
          }) : searches.map((sewadar) => {
            return (
              <>
                <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                  setView(sewadar);
                  setViewing(true);
                }}>{sewadar.name}</button>
              </>

            )
          })}

        </div> : <Sewadar setViewing={setViewing} sewadar={view} id={id} />}
      </div> : <div style={{ padding: "30px" }}>
        <UI sewadars={sewadars.filter((e) => {
            return e?.fStatus === "Active"
          })} setPrinting={setPrinting} setNoNav={setNoNav} id={id} />
      </div>}
    </div>
  );
}

export default MainApp;
