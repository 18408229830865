import { faArrowLeftLong, faBackward, faBacon, faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react';
import ids from "../assets/id.png";
import axios from "axios";
import html2canvas from 'html2canvas';

function Sewadar({ sewadar, setViewing, id }) {
    const idRef = useRef();
    const [creating, setCreating] = useState(false);
    const returnBadgeNo = () => {
        if (sewadar.badgeNo < 10) {
            return `00${sewadar.badgeNo}`
        }
        if (sewadar.badgeNo < 100) {
            return `0${sewadar.badgeNo}`
        }
        else {
            return sewadar.badgeNo;
        }
    };
    useEffect(() => {
        const canvas = idRef.current;
        const context = canvas.getContext("2d");
        const idImg = new Image();
        idImg.setAttribute('crossOrigin', 'anonymous');
        idImg.src = id.badgeImage;
        idImg.onload = async () => {
            context.drawImage(idImg, 0, 0, canvas.width, canvas.height);
            var img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.src = sewadar.sewadarImage;
            console.log(id)
            img.onload = () => {
                context.drawImage(img, parseInt(id.xPhoto), parseInt(id.yPhoto), 450, 450);

            };
            context.fillStyle = id.color;
            context.textAlign = "center";

            //Setting Name
            context.font = '600 60px times-new-roman';
            var textString = sewadar.name,
                textWidth = context.measureText(textString).width;
            context.fillText(sewadar.name, id.xName, id.yName);

            //Setting Sewa
            context.font = '600 38px times-new-roman';
            var textString = `${sewadar.department || ""} - ${sewadar.role || ""}`,
                textWidth = context.measureText(textString).width;
            context.fillText(textString, id.xSewa, id.ySewa);

            //Setting Access Code

            context.font = '600 40px times-new-roman';
            var textString = `${sewadar.badgeCode || ""}-${sewadar.badgeNo ? returnBadgeNo() : ""}`,
                textWidth = context.measureText(textString).width;
            context.fillText(textString, id.xBadge, id.yBadge);

        };
        // console.log
        setCreating(true);
    }, []);

    const getBase64FromUrl = async (url) => {
        // axios.set('Access-Control-Allow-Origin', '*');
        const data = await axios({
            url: url,
            origin: true
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    const createID = () => {

    }
    const downloadBadge = async () => {
        const canvas = await html2canvas(idRef.current);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = `Badge-${sewadar.name}.jpg`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    }
    return (
        <div style={{ marginBottom: "20px" }}>
            <button className="btn btn-primary" style={{ fontSize: "25px", borderRadius: "50%", marginLeft: "8px" }} onClick={() => { setViewing(false) }} ><FontAwesomeIcon icon={faArrowLeftLong} /></button>


            <div >
                <div style={{ textAlign: "center" }}>
                    <img className='img-thumbnail' src={sewadar.sewadarImage} style={{ maxHeight: "400px" }} />
                    <br />
                    <div >
                    {sewadar.department ? null : <span class="badge rounded-pill text-bg-danger mt-4" style={{ fontSize: "18px" }}>Sewa Not Assigned Yet</span>}
                    <h1>{sewadar.name}</h1>
                    <button className="btn btn-primary" style={{ fontSize: "18px", marginLeft: "8px", alignItems: "center" }} onClick={downloadBadge} ><FontAwesomeIcon style={{ marginRight: "10px" }} icon={faDownload} />Download Badge</button>
                    </div>

                    <label style={{ fontSize: "18px", marginTop: 16 }}>Phone Number - {sewadar.phoneNum}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Gender - {sewadar.gender}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Prefered Days:</label>
                    <ul style={{ listStyle: "none", justifyContent: "center" }}>
                        {sewadar?.days?.map((day) => {
                            return (
                                <li>- {day}</li>
                            )
                        })}
                    </ul>
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Date of Birth - {sewadar.dob}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Address - {sewadar.address}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Knows about Guruji Since - {sewadar.knAGuruji}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>In Sewa Since - {sewadar.inSewaSince}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Occupation - {sewadar.occupation}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Office Address - {sewadar.ofAddress}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Emergency Contact Name - {sewadar.emCon}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Emergency Contact Phone - {sewadar.emPho}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {sewadar.ref1Con}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {sewadar.ref1Pho}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {sewadar.ref2Con}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Reference 1 Contact Name - {sewadar.ref2Pho}</label>
                    <br />
                    <label style={{ fontSize: "18px", marginTop: 16 }}>Remarks/Comments - {sewadar.remarks}</label>
                    <div style={{ textAlign: "center" }}>
                        <canvas ref={idRef} width={1050} height={1500} style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Sewadar
