import axios from 'axios';
import { ref, uploadBytesResumable } from 'firebase/storage';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef } from 'react';
// import id from "../assets/id.png";
import { storage } from '../firebase';

function IDCard({ sewadar, id, doc, isLast }) {
    const idRef = useRef();
    const imgRef = useRef();
    const getBase64 = async (url) => {
        try {
            let image = await axios.get(url, { responseType: 'arraybuffer' });
            let raw = Buffer.from(image.data).toString('base64');
            return "data:" + image.headers["content-type"] + ";base64," + raw;
        } catch (error) {
            console.log(error)
        }
    }
    const returnBadgeNo = () => {
        if (sewadar.badgeNo < 10) {
            return `00${sewadar.badgeNo}`
        }
        if (sewadar.badgeNo < 100) {
            return `0${sewadar.badgeNo}`
        }
        else {
            return sewadar.badgeNo;
        }
    };

    useEffect(() => {
        const canvas = idRef.current;
        const context = canvas.getContext("2d");
        const idImg = new Image();
        idImg.setAttribute('crossOrigin', 'anonymous');
        idImg.src = id.badgeImage;
        idImg.setAttribute('crossOrigin', 'anonymous');
        idImg.onload = async () => {
            context.drawImage(idImg, 0, 0, canvas.width, canvas.height);
            var img = new Image();
            img.src = await sewadar.sewadarImage;
            await img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                context.drawImage(img, parseInt(id.xPhoto), parseInt(id.yPhoto), 450, 450);

            };
            context.fillStyle = id.color;
            context.textAlign = "center";
            // context.fontFamily = "Tahoma";

            //Setting Name
            context.font = '600 60px tahoma';
            var textString = sewadar.name,
                textWidth = context.measureText(textString).width;
            context.fillText(sewadar.name, id.xName, id.yName);

            //Setting Sewa
            context.font = '600 38px tahoma';
            var textString = `${sewadar.department} - ${sewadar.role}`,
                textWidth = context.measureText(textString).width;
            context.fillText(textString, id.xSewa, id.ySewa);

            //Setting Access Code

            context.font = '600 40px tahoma';
            var textString = `${sewadar.badgeCode}-${returnBadgeNo()}`,
                textWidth = context.measureText(textString).width;
            context.fillText(textString, id.xBadge, id.yBadge);
            // const canvasx = await html2canvas(canvas);
            // setTimeout(() => {
            // const data = canvasx.toDataURL('image/jpeg', 1.0);
            // console.log(data);
            let data = canvas.toDataURL();
            // doc.insertPage()
            doc.addImage(data, 'JPEG', 0, 0, 220, 305);
            doc.addImage(img, 'JPEG', 65, 135, 90, 90);
            // if(!isLast){
                doc.insertPage();
            // }
            // }, 1000);

        };

    }, []);

    // const setID = async (blob) => {
    //     let ns = await ref(storage, `ns/${sewadar.id}`);
    //     await uploadBytesResumable(ns, blob);
    //   }


    return (
        <div>
            <div style={{ textAlign: "center", opacity: 0 }}>
                {/* <img ref={imgRef} src={sewadar.sewadarImage} style={{ display:"none" }} /> */}
                <canvas ref={idRef} width={1050} height={1500} style={{ maxWidth: "100%", height: "auto", borderRadius: "0px" }} />
            </div>
        </div>
    )
}

export default IDCard
