import { useEffect, useRef, useState } from 'react';
import isEmpty from "../helpers/isEmpty";
import { faDownload, faL, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import sewas from '../data/sewas';
import genders from '../data/genders';
import badgetypes from '../constants/badgetypes';

// import phoneCheck from '../helpers/phoneCheck';
// import "./App.css";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import roles from '../data/roles';



export default function AddSewadar({ sewadars }) {
  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [gender, setGender] = useState(genders[0]);
  const [loading, setLoading] = useState(false);


  const idRef = useRef();

  const [sewaDepartment, setSewaDepartment] = useState(sewas[0]);
  const [role, setRole] = useState(roles[0]);
  const [badgeCode, setBadgeCode] = useState(badgetypes[0]);
  const [sewadarImage, setSewadarImage] = useState("");


  const setCorrectGender = (e) => {
    setGender(e.target.value);
  }
  const setCorrectSewa = (e) => {
    console.log(e.target.value)
    setSewaDepartment(e.target.value);
  }

  const setCorrectRole = (e) => {
    setRole(e.target.value);
  }

  const setCorrectCode = (e) => {
    setBadgeCode(e.target.value);
  }

  const getBadgeMax = () => {
    const badgeNos = sewadars.map((e) => {
      return e.badgeNo
    })
    let largest = badgeNos[0];
    for (let i = 1; i < badgeNos.length; i++) {
      if (badgeNos[i] > largest)
        largest = badgeNos[i];
    }
    return largest;
  }
  const createSewadar = async () => {
    setLoading(true);
    const badgeNo = await getBadgeMax();
    const docRef = await addDoc(collection(db, "sewadars"), {
      name: name,
      phoneNum: phoneNum,
      gender: gender,
      role: role,
      department: sewaDepartment,
      badgeCode: badgeCode,
      badgeNo: parseInt(badgeNo + 1),
      createdAt: serverTimestamp(),
      fStatus: "Active",
    }).then(async ({ id }) => {
      const storageRef = await ref(storage, `sewadars/${id}`);
      await uploadBytesResumable(storageRef, sewadarImage);
      await getDownloadURL(storageRef).then(async (url) => {
        await updateDoc(doc(db, "sewadars", id), {
          sewadarImage: url,
        }).then(() => {
          window.alert(`Sewadar Created with Badge: ${badgeCode}-${badgeNo + 1}`);
          setLoading(false);
        });
      })
    });
  }


  return (
    <div className='body'>
      {!loading ? <div>
        <div>
          <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
            Add Sewadar
          </h1>
          <div style={{ width: "100%", padding: 30 }}>
            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Name*</label>
              <input type="text" class="form-control" placeholder="Enter your name" style={{ fontSize: 18 }} value={name} onChange={(e) => { setName(e.target.value) }} />
            </div>
            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewa Department*</label>
              <select className="form-select choiceSel" aria-label="Sewa Department" onChange={setCorrectSewa}>
                {sewas.map((sewa) => {
                  return (
                    <option value={sewa} className='optionSel'>{sewa}</option>
                  )
                })}
              </select>
            </div>
            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewa Role*</label>
              <select className="form-select choiceSel" aria-label="Sewa Department" onChange={setCorrectRole}>
                {roles.map((role) => {
                  return (
                    <option value={role} className='optionSel'>{role}</option>
                  )
                })}
              </select>
            </div>

            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Gender*</label>
              <select className="form-select choiceSel" aria-label="What's your gender" onChange={setCorrectGender}>
                {genders.map((gender) => {
                  return (
                    <option value={gender} className='optionSel'>{gender}</option>
                  )
                })}
              </select>
            </div>

            <div class="mb-3">
              <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Badge Code*</label>
              <select className="form-select choiceSel" aria-label="What's your gender" onChange={setCorrectCode}>
                {badgetypes.map((code) => {
                  return (
                    <option value={code} className='optionSel'>{code}</option>
                  )
                })}
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Phone No</label>
              <input type="number" class="form-control" placeholder="Enter your Phone No." style={{ fontSize: 18 }} value={phoneNum} onChange={(e) => { setPhoneNum(e.target.value) }} />
            </div>



            <div className="mb-3">
              <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewadar Image</label>
              <input type="file" className="form-control" aria-label="Choose file" required onChange={(e) => { setSewadarImage(e.target.files[0]) }} accept="image/*" />
              {sewadarImage ? <button className='btn btn-danger' style={{ margin: 8 }} onClick={() => setSewadarImage("")}>Clear File</button> : null}
            </div>
            <br />
          </div>
          <div style={{ textAlign: "center" }}>
            {sewadarImage ? <img src={sewadarImage ? URL.createObjectURL(sewadarImage) : ""} alt="quizImage" className='img-thumbnail' style={{ maxWidth: "150px" }} /> : null}
            <br />
            {/* {checkEmp() ? <button type="button" class="btn btn-primary btn-lg" onClick={()=>{}} style={{ margin: 38 }} data-bs-toggle="modal" data-bs-target="#confirmModal">Create Sewadar ID</button> : <button type="button" class="btn btn-secondary btn-lg" style={{ margin: 38 }}>Create Sewadar ID</button>} */}

            {name ? <button onClick={createSewadar} class="btn btn-primary btn-lg">Create Sewadar</button> : <button disabled class="btn btn-secondary btn-lg">Create Sewadar</button>}

            <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="confirmModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body" style={{ padding: "30px" }}>
                    You have been registered as a Sevadar, Kindly do not attempt to submit this form again, if you have made an error, kindly contact the SST Team.
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div> : <div>
        <img style={{ display:"block", marginLeft: "auto", marginRight: "auto" }} src={require("../assets/loading.gif")} />
      </div> }
    </div>
  )
}