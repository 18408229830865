import axios from 'axios';
import { ref, uploadBytesResumable } from 'firebase/storage';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef } from 'react';
import badgeFront from "../assets/badge-front.png";
import { storage } from '../firebase';

function PermanentBadge({ sewadar, id, doc, isLast }) {
    const idRef = useRef();
    const imgRef = useRef();
    const getBase64 = async (url) => {
        try {
            let image = await axios.get(url, { responseType: 'arraybuffer' });
            let raw = Buffer.from(image.data).toString('base64');
            return "data:" + image.headers["content-type"] + ";base64," + raw;
        } catch (error) {
            console.log(error)
        }
    }
    const returnBadgeNo = () => {
        if (sewadar.badgeNo < 10) {
            return `00${sewadar.badgeNo}`
        }
        if (sewadar.badgeNo < 125) {
            return `0${sewadar.badgeNo}`
        }
        else {
            return sewadar.badgeNo;
        }
    };

    console.log(badgeFront)
    useEffect(() => {
        if(sewadar.day !== "CC"){
        const canvas = idRef.current;
        const context = canvas.getContext("2d");
        const idImg = new Image();
        idImg.src = badgeFront;
        idImg.onload = async () => {
            let monday = "#98d6ec";
            let thursday = "#ffc42d";
            let friday = "#f6bec0";
            let saturday = "#a4c97e";
            let sunday = "#ed7343";

            if(sewadar.day === "Multicolor"){
                // Thursday
                context.fillStyle = thursday;
                context.fillRect(30, 36, 208, 580);
                
                // Friday
                context.fillStyle = friday;
                context.fillRect(208, 36, 208, 580);

                // Saturday
                context.fillStyle = saturday;
                context.fillRect(416, 36, 208, 580);

                // Sunday
                context.fillStyle = sunday;
                context.fillRect(624, 36, 208, 580);

                // Monday
                context.fillStyle = monday;
                context.fillRect(832, 36, 178, 580);
            }
            else {
                let days = sewadar.day.split(",");
                if(days.includes("Monday")){
                    context.fillStyle = monday;
                    context.fillRect(30, 36, 975, 580);
                }
                if(days.includes("Thursday")){
                    context.fillStyle = thursday;
                    context.fillRect(30, 36, 975, 580);
                }
                if(days.includes("Friday")){
                    context.fillStyle = friday;
                    context.fillRect(30, 36, 975, 580);
                }
                if(days.includes("Saturday")){
                    context.fillStyle = saturday;
                    context.fillRect(30, 36, 975, 580);
                }
                if(days.includes("Sunday")){
                    context.fillStyle = sunday;
                    context.fillRect(30, 36, 975, 580);
                }
                if(days.includes("Temporary")){
                    context.fillStyle = "white";
                    context.fillRect(30, 36, 975, 580);
                }

                // for (let index = 0; index < days.length; index++) {
                //     const element = days[index];
                //     console.log(days.length);
                    
                //     // if(element === "Monday"){
                //     //     context.fillStyle = monday;
                //     //     if(index === 0 && days.length > 1){
                //     //         context.fillRect(30, 36, 208, 580);
                //     //     }
                //     //     // else if(index >= 0 && days.length > 1){
                //     //     //     context.fillRect(208 + (index *  208), 36, 208, 580);
                //     //     // }
                //     //     // else if(index === 0 && days.length == 1){
                //     //     //     context.fillRect(30, 36, 975, 580);
                //     //     // }
                //     //     else{
                //     //         context.fillRect(30, 36, 975, 580);
                //     //     }
                //     // }
                //     // if(element === "Thursday"){
                //     //     context.fillStyle = thursday;
                //     //     if(index === 0 && days.length > 1){
                //     //         context.fillRect(30, 36, 208, 580);
                //     //     }
                //     //     else{
                //     //         context.fillRect(30, 36, 975, 580);
                //     //     }
                //     // }
                //     // if(element === "Friday"){
                //     //     context.fillStyle = friday;
                //     //     if(index === 0 && days.length > 1){
                //     //         context.fillRect(30, 36, 208, 580);
                //     //     }
                //     //     else{
                //     //         context.fillRect(30, 36, 975, 580);
                //     //     }
                //     // }
                //     // if(element === "Saturday"){
                //     //     context.fillStyle = saturday;
                //     //     if(index === 0 && days.length > 1){
                //     //         context.fillRect(30, 36, 208, 580);
                //     //     }
                //     //     else{
                //     //         context.fillRect(30, 36, 975, 580);
                //     //     }
                //     // }
                //     // if(element === "Sunday"){
                //     //     context.fillStyle = sunday;
                //     //     if(index === 0 && days.length > 1){
                //     //         context.fillRect(30, 36, 208, 580);
                //     //     }
                //     //     else{
                //     //         context.fillRect(30, 36, 975, 580);
                //     //     }
                //     // }
                    
                // }
                
            }
            

            context.drawImage(idImg, 0, 0, canvas.width, canvas.height);
            var img = new Image();
            img.src = await sewadar.sewadarImage;
            await img.setAttribute('crossOrigin', 'anonymous');
            img.onload = () => {
                context.drawImage(img, parseInt(52), parseInt(218), 300, 300);

            };
            context.fillStyle = "black";
            // context.textAlign = "center";

            //Setting Name
            context.font = 'bold 43px Tahoma';
            // console.log(sewadar.name,sewadar.name.length)
            // console.log(sewadar.name,context.measureText(sewadar.name).width)
            if(sewadar.name.length >= 21 && sewadar.name.length <= 23){
                context.font = 'bold 38px Tahoma';
            }
            if(sewadar.name.length >= 24){
                context.font = 'bold 31px Tahoma';
            }
            context.fillText(sewadar.name, 375, 280);

            //Setting Role
            context.font = '500 40px Tahoma';
            context.fillText(sewadar.role, 375, 360);

            //Setting Place
            // context.font = '500 40px Tahoma';
            // context.fillText("Inside Mandir", 375, 400);


            //Setting Day and Department
            context.font = 'bold 40px Tahoma';
            // context.fillText(`${sewadar.day === "Multicolor" ? "" : `${sewadar.day}, `}${sewadar.department}`, 375, 490);
            context.fillText(sewadar.department, 375, 490);


            //Setting Code

            console.log(sewadar.badgeNo);
            context.font = 'bold 40px Tahoma';
            context.fillText(sewadar.badgeNo, 135, 565);



            // const canvasx = await html2canvas(canvas);
            // setTimeout(() => {
            // const data = canvasx.toDataURL('image/jpeg', 1.0);
            // console.log(data);
            let data = canvas.toDataURL();
            // doc.insertPage()
            doc.addImage(data, 'JPEG', 0, 70, 210, 132);
            doc.addImage(img, 'JPEG', 11, 115, 61, 61);
            // if(!isLast){
                doc.insertPage();
            // }
            // }, 1000);
        };
    }
    }, []);

    // const setID = async (blob) => {
    //     let ns = await ref(storage, `ns/${sewadar.id}`);
    //     await uploadBytesResumable(ns, blob);
    //   }


    return (
        <div>
            <div style={{ textAlign: "center", opacity: 1 }}>
                <canvas ref={idRef} width={1040} height={651} style={{ maxWidth: "100%", height: "auto", borderRadius: "0px" }} />
            </div>
        </div>
    )
}

export default PermanentBadge
