import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsPDF from 'jspdf';
import React, { useState } from 'react'
import IDCard from './IDCard';
import sewas from "../data/sewas";
import SewadarCheck from './SewadarCheck';
const XLSX = require("xlsx");

function UI({ sewadars, setPrinting, setNoNav, id }) {
    const [startFrom, setStartFrom] = useState(0);
    const [endFrom, setEndFrom] = useState(0);
    const [excelFile, setExcelFile] = useState(null);
    const [chosenDataset, setChosenDataset] = useState([]);

    const [chosens, setChosens] = useState([]);
    const [step, setStep] = useState(1);
    const [department, setDepartment] = useState(sewas[0]);
    const [departmentSewadars, setDepartmentSewadars] = useState([])

    // Step 1: Department Chosen
    // Step 2: Sewadars Chosen
    // Step 3: Sewadars badges printed


    // var doc = new jsPDF("portrait", "px", [1050, 1500]);
    var doc = new jsPDF();

    const setCorrectDepartment = (e) => {
        setDepartment(e.target.value);
    }

    const chooseDepartment = () => {
        const fid = sewadars.filter((e) => {
            if(department === "&"){
                if(e?.department?.includes("&")){
                    return true
                }                
            }
            else{
                return e.department ? e?.department?.trim() === department?.trim() : null
            }
        })
        setDepartmentSewadars(fid);
        setStep(2);
    }


    const startPrinting = async () => {
        let pickedAr = [];
        await chosens.map((ID) => {
            sewadars.map((sewadar) => {
                if (sewadar.id === ID) {
                    pickedAr.push(sewadar);
                }
            })
        });
        setChosenDataset(pickedAr);
        setStep(3);
    };


    return (
        <div>
            <h1>{sewadars.length} Sewadars</h1>
            <button className="btn btn-primary" style={{ fontSize: "25px", borderRadius: "50%", marginLeft: "8px" }} onClick={() => { setPrinting(false) }} ><FontAwesomeIcon icon={faArrowLeftLong} /></button>
            <div>
                {step === 1 ? <div>
                    <div class="mb-3 mt-4">
                        <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Sewa Department*</label>
                        <select className="form-select choiceSel" aria-label="Sewa Department" value={department} onChange={setCorrectDepartment}>
                            {sewas.map((sewa) => {
                                return (
                                    <option value={sewa} className='optionSel'>{sewa}</option>
                                )
                            })}
                        </select>
                        <button className='btn btn-primary mt-4' onClick={chooseDepartment}>Continue</button>
                    </div>
                </div> : null}

                {step === 2 ? <div>
                    <div class="mb-3 mt-4">
                        <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>{departmentSewadars?.length} {department} Sewadars</label>
                        <br />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <button className='btn btn-primary mt-4' onClick={startPrinting}>Continue Printing</button>
                        {chosens.length !== departmentSewadars.length ? <button className='btn btn-info mt-4' onClick={async ()=>{
                            await setChosens([]);
                            departmentSewadars.map((sewadar)=>{
                                setChosens(oldArray => [...oldArray, sewadar.id]);
                            })
                        }}>Select All</button> : <button className='btn btn-info mt-4' onClick={()=>{
                            setChosens([]);
                        }}>Deselect All</button>}
                            </div>
                            <label lass="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>{chosens?.length} Sewadars Selected</label>
                        <div class="list-group" style={{ padding: "10px" }}>
                            {departmentSewadars.map((sewadar) => {
                                return (
                                    <SewadarCheck sewadar={sewadar} chosens={chosens} setChosens={setChosens} />
                                )

                            })}
                        </div>
                    </div>
                </div> : null}

                {step === 3 ? <div>
                    <button type="button" className='btn btn-primary mt-4' onClick={() => {
                        if((doc.getNumberOfPages()-1) >= chosens.length){
                            doc.save(`${department}-Badges.pdf`);
                        }
                        else{
                            window.alert(`PDF Still processing - ${(doc.getNumberOfPages()-1)}/${chosens.length} `)
                        }
                        // console.log(doc.getNumberOfPages());

                    }}>Download Badges</button>
                    {chosenDataset.map((sewadar) => {
                        console.log(sewadar);
                        return (
                            <>
                                <IDCard id={id} sewadar={sewadar} doc={doc} />
                            </>
                        )
                    })}
                </div> : null}
            </div>
        </div>
    )
}

export default UI
