import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEffect } from 'react';

const columns = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'id', headerName: 'ID', width: 120 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'phoneNum', headerName: 'Phone Number', width: 200 },
  { field: 'department', headerName: 'Department', width: 200 },
  { field: 'role', headerName: 'Role', width: 200 },
  { field: 'badgeCode', headerName: 'Badge Code', width: 200 },
  { field: 'badgeNo', headerName: 'Badge No', width: 200 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function Filter({ sewadars }) {
//   const [sData, setSData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     sewadars.map(async (sewadar, index)=>{
//         await setSData(oldArray => [...oldArray, {
//             id: index+1, 
//             name: sewadar.name, 
//             age: 13, 
//             phone: sewadar.phoneNum,
//             department: sewadar.department,
//             role: sewadar.role,
//             badgeCode: sewadar.badgeCode,
//             badgeNo: sewadar.badgeNo
//         }]);
//         if(index === sewadars.length){
//             setLoading(false);
//         }
//     })
//   }, []);
  
//   console.log(sData);
  return (
    <div style={{ height: "100vh", width: '100%' }}>
      <DataGrid
        rows={sewadars}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  );
}