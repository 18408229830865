import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAzkv3ODvhSRydhQFbSA0sbYiMB-o9VrT0",
  authDomain: "gkammsevadar.firebaseapp.com",
  projectId: "gkammsevadar",
  storageBucket: "gkammsevadar.appspot.com",
  messagingSenderId: "695182717030",
  appId: "1:695182717030:web:820596fe1dcedf48d3bda7"
};



const app = initializeApp(firebaseConfig);

const db = getFirestore();
const auth = getAuth();
const storage = getStorage();


export { db, auth, storage }; 