import React from 'react';
import { Link } from 'react-router-dom';
import guruji from "../assets/guruji.jpg";


function Navbar() {
    return (
        <>
            {/* <nav class="navbar bg-light" style={{ textAlign: "center", justifyContent: "center" }}>
            <div>
                <a class="navbar-brand header-title" href="/"><img src={guruji} style={{ borderRadius: "50%", height: "80px", margin: "0 12px 0 9px", width: "80px" }} /><b style={{ fontSize: "25px" }}>Sevadars Administrator</b></a>
            </div>
        </nav> */}
            <nav class="navbar navbar-expand-lg" style={{backgroundColor: "#fff0ff"}}>
                <div class="container-fluid">
                <Link to="/" class="navbar-brand header-title" href="/"><img src={guruji} style={{ borderRadius: "50%", height: "80px", margin: "0 12px 0 9px", width: "80px" }} /><b style={{ fontSize: "25px" }}>GKAMM Admin</b></Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/" style={{ fontSize: "20px", marginRight: "10px" }}>Sewadars</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/entries" style={{ fontSize: "20px", marginRight: "10px" }}>New Entries</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/filter" style={{ fontSize: "20px", marginRight: "10px" }}>Filter</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/attendance" style={{ fontSize: "20px", marginRight: "10px" }}>Attendance</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/operations" style={{ fontSize: "20px", marginRight: "10px" }}>Maintenance</Link>
                            </li>
                            <li class="nav-item">
                            <Link class="nav-link active" aria-current="page" to="/add" style={{ fontSize: "20px", marginRight: "10px" }}>Create</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/event" style={{ fontSize: "20px", marginRight: "10px" }}>Events</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" aria-current="page" to="/permanent" style={{ fontSize: "20px", marginRight: "10px" }}>Permanent Badges</Link>
                            </li>
                        </ul>
                        {/* <form class="d-flex" role="search">
                            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button class="btn btn-outline-success" type="submit">Search</button>
                        </form> */}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar