import React from 'react'
import IDCard from './IDCard'

function PDFFile({ sewadars }) {
    return (
        <div>
            {sewadars.map((sewadar) => {
                return (
                    <>
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                        <IDCard sewadar={sewadar} />
                    </>
                )
            })}
        </div>
    )
}

export default PDFFile