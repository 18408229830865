import React, { useEffect, useRef, useState } from 'react'
import isEmpty from '../helpers/isEmpty';
import guruji from "../assets/guruji.jpg";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
function Event({ sewadars }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [badge, setBadge] = useState("");
    const [xName, setXName] = useState(0);
    const [yName, setYName] = useState(0);
    const [xSewa, setXSewa] = useState(0);
    const [ySewa, setYSewa] = useState(0);
    const [xBadge, setXBadge] = useState(0);
    const [yBadge, setYBadge] = useState(0);
    const [xPhoto, setXPhoto] = useState(0);
    const [yPhoto, setYPhoto] = useState(0);
    const [eventsTab, setEventsTab] = useState(true);
    const [addTab, setAddTab] = useState(false);
    const [color, setColor] = useState("");
    const [id, setId] = useState({});

    const idRef = useRef();
    const createEvent = async () => {
        try {
            await setDoc(doc(db, "default", "event"), {
                eventName: name,
                eventDesc: description,
                xName: xName,
                yName: yName,
                xBadge: xBadge,
                yBadge: yBadge,
                xSewa: xSewa,
                ySewa: ySewa,
                xPhoto: xPhoto,
                yPhoto: yPhoto,
                color: color,
                badgeImage: "https://firebasestorage.googleapis.com/v0/b/gkammsevadar.appspot.com/o/default-g%2FdefaultBadge.png?alt=media&token=fcdddbf5-5104-4285-9378-bb15d76e0d7d"
            }).then(()=>{
                sewadars.map((sewadar)=>{
                    updateDoc(doc(db, "sewadars", sewadar.id), {
                        attendance: false,
                        status: "Inactive"
                    })
                })
            });
            const storageRef = await ref(storage, "default-g/defaultBadge");
            await uploadBytesResumable(storageRef, badge);
            setName("");
            setDescription("");
            setXName(0);
            setYName(0);
            setXBadge(0);
            setYBadge(0);
            setXSewa(0);
            setYSewa(0);
            setXPhoto(0);
            setYPhoto(0);
            setBadge("");
        } catch (error) {
            window.alert("There has been an error creating the event, please try again.")
        }
    };
    useEffect(() => {
        if (badge) {
            const canvas = idRef.current;
            const context = canvas.getContext("2d");
            const idImg = new Image();
            idImg.src = URL.createObjectURL(badge);
            idImg.onload = async (e) => {
                context.drawImage(idImg, 0, 0, canvas.width, canvas.height);
                var img = new Image();
                img.setAttribute('crossOrigin', 'anonymous');
                img.src = guruji;
                img.onload = () => {
                    context.drawImage(img, xPhoto, yPhoto, 450, 450);

                };
                context.fillStyle = color;
                context.textAlign = "center";

                //Setting Name
                context.font = '600 60px times-new-roman';
                var textString = "Name",
                    textWidth = context.measureText(textString).width;
                context.fillText(textString, xName, yName);

                //Setting Sewa
                context.font = '600 38px times-new-roman';
                var textString = "Department - Role",
                    textWidth = context.measureText(textString).width;
                context.fillText(textString, xSewa, ySewa);

                //Setting Access Code

                context.font = '600 40px times-new-roman';
                var textString = "AAA-001",
                    textWidth = context.measureText(textString).width;
                context.fillText(textString, xBadge, yBadge);

            };
        }
    }, [badge, xName, yName, xSewa, ySewa, xBadge, yBadge, xPhoto, yPhoto, color]);

    const setBadged = (e) => {
        console.log(e.target.files[0])
        setBadge(e.target.files[0]);
    };
    const isEmp = () => {
        if (isEmpty(name) || isEmpty(description) || xName === 0 || yName === 0 || xBadge === 0 || yBadge === 0 || xSewa === 0 || ySewa === 0 || xPhoto === 0 || yPhoto === 0) {
            return true;
        }
        else {
            return false;
        }
    }
    const setTab = (tabName) => {
        if (tabName === "events") {
            setEventsTab(true);
            setAddTab(false);
        }
        if (tabName === "add") {
            setEventsTab(false);
            setAddTab(true);
        }
    }
    useEffect(() => {
        getEvent();
    }, []);

    const getEvent = async () => {
        const docRef = doc(db, "default", "event");
        const docSnap = await getDoc(docRef);
        setId(docSnap.data());
    }

    return (
        <div style={{ padding: "30px" }}>
            <ul class="nav nav-pills nav-justified" style={{ marginBottom: "20px" }}>
                <li class="nav-item">
                    <button class={`nav-link ${eventsTab ? "active" : ""}`} aria-current="page" style={{ fontSize: "25px" }} onClick={() => { setTab("events") }}>Event Details</button>
                </li>
                <li class="nav-item" style={{ marginLeft: "10px" }}>
                    <button class={`nav-link ${addTab ? "active" : ""}`} aria-current="page" style={{ fontSize: "25px" }} onClick={() => { setTab("add") }}>Add Event</button>
                </li>
            </ul>
            {addTab ? <>

                <div class="mb-3">
                    <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Event Name*</label>
                    <input type="text" class="form-control" placeholder="Enter the Event Name" value={name} onChange={(e) => { setName(e.target.value) }} />
                </div>
                <div class="mb-3">
                    <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Event Description*</label>
                    <textarea class="form-control" placeholder='Enter the Event Description, or requirements from Sewadars Enrolling' rows="3" value={description} onChange={(e) => { setDescription(e.target.value) }}></textarea>
                </div>
                <div className="mb-3">
                    <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Badge For Event*</label>
                    <input type="file" className="form-control" aria-label="Choose file" required onChange={setBadged} accept="image/*" />
                    {badge ? <button className='btn btn-danger' style={{ margin: 8 }} onClick={() => setBadge("")}>Clear File</button> : null}
                </div>
                <br />
                <div style={badge ? { display: "block" } : { display: "none" }}>
                    <div style={{ textAlign: "center" }}>
                        {/* {badge ? <img src={badge ? URL.createObjectURL(badge) : ""} alt="quizImage" className='img-thumbnail' style={{ width: "1050px", height: "1500px" }} /> : null} */}
                        <canvas ref={idRef} width={1050} height={1500} style={{ maxWidth: "100%", height: "auto", border: "2px solid black" }} />
                    </div>
                    <hr />
                    <h3 style={{ textAlign: "center" }}>Image Synchronization</h3>
                    <div class="mb-3">
                        <label class="form-label" style={{ fontSize: 18, fontWeight: "bold" }}>Badge Main Color</label>
                        <input className='form-control' type={"color"} value={color} onChange={(e) => { setColor(e.target.value) }} style={{ marginBottom: "10px" }} />
                        
                        <input type="number" class="form-control" placeholder="Enter X Axis For Name" value={xName !== 0 ? xName : ""} onChange={(e) => { setXName(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter Y Axis For Name" value={yName !== 0 ? yName : ""} onChange={(e) => { setYName(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter X Axis For Sewa" value={xSewa !== 0 ? xSewa : ""} onChange={(e) => { setXSewa(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter Y Axis For Sewa" value={ySewa !== 0 ? ySewa : ""} onChange={(e) => { setYSewa(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter X Axis For Badge Code" value={xBadge !== 0 ? xBadge : ""} onChange={(e) => { setXBadge(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter Y Axis For Badge Code" value={yBadge !== 0 ? yBadge : ""} onChange={(e) => { setYBadge(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter X Axis For Photo" value={xPhoto !== 0 ? xPhoto : ""} onChange={(e) => { setXPhoto(e.target.value) }} style={{ marginBottom: "10px" }} />
                        <input type="number" class="form-control" placeholder="Enter Y Axis For Photo" value={yPhoto !== 0 ? yPhoto : ""} onChange={(e) => { setYPhoto(e.target.value) }} style={{ marginBottom: "10px" }} />
                    </div>
                </div>
                {
                    isEmp() ? <button type="submit" className="btn btn-secondary sign">Create Event</button> :
                        <button type="submit" data-bs-toggle="modal" data-bs-target="#confirmModal" onClick={createEvent} className="btn btn-primary sign">Create Event</button>
                }
                <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="confirmModalLabel">Event Created</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" style={{ padding: "30px" }}>
                                Jai Guruji the Event Has been created now it will be visible to everybody on the Home Page Of GKAMM 🙏🏻
                            </div>

                        </div>
                    </div>
                </div>
            </> : <div style={{ padding: "18px", textAlign: "center" }}>
                <h2 style={{ textAlign: "center", marginBottom: "30px" }}>Ongoing Event - {id.eventName}</h2>
                <p style={{ fontSize: "20px" }}>{id.eventDesc}</p>
                <label style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "20px" }}>Event Badge - </label>
                <br />
                <img className='img-thumbnail' src={id.badgeImage} style={{ border: "2px solid black" }} />

            </div>}
        </div>
    )
}

export default Event