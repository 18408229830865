
import Navbar from '../components/Navbar';
import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import UI from '../components/UI';
import Sewadar from '../components/Sewadar';
import isEmpty from '../helpers/isEmpty';
const XLSX = require("xlsx");

function AttSewadars({ setNoNav, sewadars, id }) {
  const [viewing, setViewing] = useState(false);
  const [view, setView] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searches, setSearches] = useState([]);

  

  const search = () => {
    var aSearch = searchTerm.toLowerCase();
    const searchResults = sewadars.filter(function (e) {
      return e?.name?.toLowerCase().includes(aSearch)
    });

    setSearches(searchResults);
  };

  useEffect(() => {
    if (isEmpty(searchTerm) === true) {
      setSearches([]);
    }
    else {
      search()
    }
  }, [searchTerm]);
  

  return (
    <div>
      <div>
        {viewing === false ? <div class="list-group" style={{ padding: "10px" }}>

          
          <input type='text' className='form-control' value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder="Search..." style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }} />
          {searchTerm ? <button className="btn btn-primary" style={{ fontSize: "18px", marginTop: 16, marginBottom: 12}} onClick={()=>{setSearchTerm(""); setSearches([]);}}>Clear Results</button> : null}

          







          <h2 style={{ marginLeft: "20px" }}>Sewadars</h2>
          {searches.length === 0 ? sewadars.map((sewadar) => {
            return (
              <>
                <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                  setView(sewadar);
                  setViewing(true);
                }}>{sewadar.name}</button>
              </>

            )
          }) : searches.map((sewadar) => {
            return (
              <>
                <button type="button" class="list-group-item list-group-item-action" style={{ fontSize: "20px" }} onClick={() => {
                  setView(sewadar);
                  setViewing(true);
                }}>{sewadar.name}</button>
              </>

            )
          })}

        </div> : <Sewadar setViewing={setViewing} sewadar={view} id={id} />}
      </div>
    </div>
  );
}

export default AttSewadars;
