const sewas = [
    "Cleaning",
    "D2 Holding Area",
    "Darbar",
    "Distribution",
    "Jal Distribution",
    "Langar Distribution",
    "Darbar Distribution",
    "Floor",
    "Joda / Holding Area",
    "ITC Logistics",
    "Prasad Distribution",
    "Mandir & ITC Logistics",
    "Chief Coordinator",
    "VIP Area",
    "Joda",
    "Holding Area",
    "Langar",
    "Core Committee",
    "Mandir Management",
    "Music",
    "Pantry",
    "Photography",
    "QRT",
    "QRT/Set-up",
    "Security",
    "Sewadar Refreshment",
    "SST",
    "Store",
    "Waste Management",
    "Wind Down",
    "Wind Up",
    "IT",
    "Parking",
    "Staff",
    "Logistics",
    "Floor & Holding Area",
    "-",
    "&",
    "Staff",
    "Staff Family",
    "Imperial Holding Area",
    "Core Committee Member",
    "QST",
    "Shuttle Sewa",
    "Washroom",
    "Wind-Up",
    "Wind-Down",
    "Helipad Holding Area",
    "SST & QST",
    "QRT & QST",
    "QST & Wind Up",
    "Distribution-D2",
    "Langar-D2",
    "QRT-D2",
    "QST & Wind Up",
    "QST-D2",
    "QST-Helipad",
    "QST-M Point",
    "Sewadar Refreshment-D2",
    "SST & QST",
    "SST & QST-D2",
    "SST-D2",
    "Store-D2",
    "Waste Management-D2",
    "Wind Up-D2",
    "D2 - Helipad",
    "D2 - Helipad & Z Point",
    "D2 - Z Point",
    "Attendance"
]
export default sewas
