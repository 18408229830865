import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { auth, db } from './firebase';
import Sewadar from './components/Sewadar';
import IDCard from './components/IDCard';
import PDFFile from './components/PDFFile';
import UI from './components/UI';
import { Route, Routes } from 'react-router-dom';
import SignIn from './screens/SignIn';
import bg from "./assets/background.jpg";
import admins from './constants/admins';
import MainApp from './screens/MainApp';
import Event from './screens/Event';
import Attendance from './screens/Attendance';
import Operations from './screens/Operations';
import AddSewadar from './screens/AddSewadar';
import Entries from './screens/Entries';
import Filter from './screens/Filter';
import AttNav from './components/AttNav';
import AttSewadars from './screens/AttSewadars';
import PermanentBadges from './screens/PermanentBadges';

function App() {
  const [user, setUser] = useState(false);
  const [noNav, setNoNav] = useState(false);
  const [sewadars, setSewadars] = useState([]);
  const [entries, setEntries] = useState([])
  const [id, setId] = useState({});

  const getSewadars = async () => {
    // try {
    const sewadarsData = await query(collection(db, 'sewadars'));
    onSnapshot(sewadarsData, (querySnapshot) => {
      setSewadars(querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })))
    });
    const docRef = doc(db, "default", "event");
    const docSnap = await getDoc(docRef);
    setId(docSnap.data());
    setUser(true)

  };


  const getEntries = async () => {
    const entriesData = await query(collection(db, 'entries'));
    onSnapshot(entriesData, (querySnapshot) => {
      setEntries(querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      })))
    });
  };

  useEffect(() => {
    // auth.signOut();
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        getSewadars();
        getEntries();
      }
      else {
        setUser(false);
      }
    })
  }, [])


  return (
    <div>
      {user === false ? <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="*" element={<SignIn />} />
      </Routes> : admins.includes(auth.currentUser.email) ? <div>
        {noNav === false ? <Navbar /> : null}
        <Routes>
          <Route path="/" element={<MainApp setNoNav={setNoNav} sewadars={sewadars} id={id} />} />
          <Route path="/entries" element={<Entries entries={entries} />} />
          <Route path="/filter" element={<Filter entries={entries} sewadars={sewadars} />} />
          <Route path="/event" element={<Event sewadars={sewadars} />} />
          <Route path="/operations" element={<Operations sewadars={sewadars} />} />
          <Route path="/permanent" element={<PermanentBadges sewadars={sewadars} />} />
          <Route path="/add" element={<AddSewadar sewadars={sewadars.filter((e) => {
            return e?.fStatus === "Active"
          })} />} />
          <Route path="/attendance" element={<Attendance sewadars={sewadars.filter((e) => {
            return e?.fStatus === "Active"
          })} />} />
        </Routes>
      </div> : <div>
        <AttNav />
        <Routes>
          
          <Route path="/" element={<Attendance sewadars={sewadars.filter((e) => {
            return e?.fStatus === "Active"
          })} />} />
          <Route path="/sewadars" element={<AttSewadars id={id} sewadars={sewadars} />} />
          <Route path="/add" element={<AddSewadar sewadars={sewadars.filter((e) => {
            return e?.fStatus === "Active"
          })} />} />
        </Routes>
      </div>}

    </div>
  );
}

export default App;
